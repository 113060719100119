import { render, staticRenderFns } from "./OurStats.vue?vue&type=template&id=3af278d8&scoped=true&"
import script from "./OurStats.vue?vue&type=script&lang=js&"
export * from "./OurStats.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3af278d8",
  null
  
)

export default component.exports