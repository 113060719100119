import { render, staticRenderFns } from "./EntityNew.vue?vue&type=template&id=45f1a91c&scoped=true&"
import script from "./EntityNew.vue?vue&type=script&lang=js&"
export * from "./EntityNew.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45f1a91c",
  null
  
)

export default component.exports