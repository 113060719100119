import { render, staticRenderFns } from "./SerpToolbarMenu.vue?vue&type=template&id=0b327b76&scoped=true&"
import script from "./SerpToolbarMenu.vue?vue&type=script&lang=js&"
export * from "./SerpToolbarMenu.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b327b76",
  null
  
)

export default component.exports