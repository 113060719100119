import { render, staticRenderFns } from "./SerpTabs.vue?vue&type=template&id=5f50e1f8&scoped=true&"
import script from "./SerpTabs.vue?vue&type=script&lang=js&"
export * from "./SerpTabs.vue?vue&type=script&lang=js&"
import style0 from "./SerpTabs.vue?vue&type=style&index=0&id=5f50e1f8&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f50e1f8",
  null
  
)

export default component.exports